@import "styles/mixins";

.footer {
  width: 100%;
  // border-top: 1px solid var(--clr-neutral-250);
  border-top: 3px solid var(--clr-primary-300);
  background: var(--clr-neutral-125);

  ul {
    list-style-type: none;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 3fr auto;
    grid-template-areas: "menus newsletter";
    gap:2rem;
    justify-content: space-between;
    padding: 2rem 0 3rem 0rem;
    margin-inline: auto;
    @include mediumScreen() {
      padding:0rem 0rem 2rem 0rem;
      grid-template-columns: 1fr;
      grid-template-areas: 
      "newsletter"
      "menus";
    }
    @include largeMobile(){
      padding:0px;
    }
  }
  &__menus {
    grid-area:menus;
    display: flex;
    // justify-content: space-evenly;
    gap:1rem;
    text-transform: capitalize;
    list-style-type: none;
    .menu {
      width:20%;
      // min-width:200px;
      font-size:1rem;
      &:before {
        content: attr(aria-label);
        font-size:1rem;
        color: var(--clr-primary-300);
        font-weight: 700;
        text-transform: capitalize;
      }
    }

    li:first-of-type {
      padding-top: 1rem;
    }

    li {
      margin-bottom: 0.5rem;
      text-transform: capitalize;
      width: fit-content;
      min-width: 48px;
      &:last-of-type {
        margin-bottom: 0;
      }
      a:hover {
        border-bottom: 1px solid var(--clr-primary-300);
      }
    }
    @include mediumScreen(){
      .menu {
        width:unset;
      }
      justify-content: space-evenly;
    }
    @include largeMobile(){
      flex-direction: column;
      gap:0;
    }
  }
  
}

.newsletter {
  grid-area:newsletter;
  @include mediumScreen(){
    padding:2rem 1rem 0rem 1rem;
  }
}

.disclaimer {
  grid-area:credits;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0rem;
  @include tablet() {
    padding: 1rem 0rem;
    justify-content: center;
    flex-direction: column;
  }
}

.terms {
  display: flex;
  flex-wrap:wrap;
  list-style-type: none;
  justify-content: center;
  font-size: var(--fs-300);
  a:hover {
    text-decoration: underline;
  }
  li:not(:last-child):after {
    content: "|";
    padding: 0px 1em;
    opacity: 0.4;
    @include largeMobile(){
      padding:0.5em;
    }
  }
}

.credits {
  display: flex;
  column-gap: 1em;
  list-style-type: none;
  justify-content: center;
  text-align: right;
  font-size: var(--fs-300);
  li:not(:first-child) {
    opacity: 0.8;
  }
  @include tablet() {
    padding-top: 1em;
    text-align: center;
    flex-wrap:wrap;
  }
}

.link {
  text-transform: capitalize;
}

.bottomBar {
  width: 100%;
  background:var(--clr-primary-300);
  color:var(--clr-neutral-150);
}

.toggle {
  border-bottom:none!important;
  div:first-of-type {
    border-top:1px solid var(--clr-neutral-250);
    background:var(--clr-neutral-150);
    color:var(--clr-neutral-800);
    padding:1rem;
  }
  div:nth-of-type(2) {
    background:var(--clr-neutral-200);
    padding-inline:1rem;
    ul {
      padding-bottom:1rem;
    }
    li:first-of-type {
      padding-top:1rem;
    }
  }
}